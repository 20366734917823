.PageTitle {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    text-decoration: underline;
}

.PageText {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}
.listOption {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #000000;
}

.center {
    max-width: 200px;
    margin: 0 auto;
}

li {
    padding: 4px 0;
}